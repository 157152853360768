<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 30rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">规则配置</div>
          <el-form-item label="划转目标金额">
            <el-input-number v-model="form.pool_get_target"></el-input-number>元，划转比例 <el-input-number v-model="form.award_scale"></el-input-number>%
            <div class="y-desc">
              任意用户购买加盟区商品，订单实际支付金额大于等于划转目标金额时按划转比例划转至资金池，为0时 所有订单均不向此资金池划转
            </div>
          </el-form-item>
          <el-form-item label="分成触发金额">
            <el-input-number v-model="form.grant_trigger_fee"></el-input-number>元，
            资金池内未分成金额满足大于等于 分成触发金额时 平均分配给所有加盟店用户
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      form:{
        award_scale:0,
        grant_trigger_fee:0,
        pool_get_target:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.franchiseStore.confFetch().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.franchiseStore.confEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>